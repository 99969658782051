import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { SocialIcon } from 'react-social-icons'
import { Flex, Text } from 'rebass'
import styled from 'styled-components'
import { ScrollTop } from './scroll-top'

const Footer = styled.footer`
  padding: 0 ${props => props.theme.paddingWrapper.mobile};
  z-index: 2;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    padding: 0 ${props => props.theme.paddingWrapper.desktop};
  }
  border-top: 0px solid ${props => props.theme.colors.secondary};
`

const StyledSocial = styled(Flex)`
  position: fixed;
  height: 80px;
  bottom: 0;
`

const SOCIAL_ICON = {
  width: 22,
  height: 22,
  marginLeft: 4,
}
const FooterComponent = () => (
  <Footer>
    <Flex justifyContent="flex-end" alignItems="center">
      <StyledSocial alignItems="center">
        <StaticQuery
          query={graphql`
            query {
              contentfulConfiguracionGeneral {
                socialNetworks
              }
            }
          `}
          render={data => {
            const { socialNetworks } = data.contentfulConfiguracionGeneral
            return (
              socialNetworks &&
              socialNetworks.map(socialNetwork => (
                <SocialIcon
                  key={socialNetwork}
                  url={socialNetwork}
                  style={SOCIAL_ICON}
                  bgColor="#4d4d4d"
                  fgColor="white"
                />
              ))
            )
          }}
        />
      </StyledSocial>

      <ScrollTop showUnder={160} />
    </Flex>
    <Flex alignItems="center" justifyContent="center" css={{ height: '80px' }}>
      <Text fontSize={1}>WOOCO 2019</Text>
    </Flex>
  </Footer>
)

export default FooterComponent
