import Img from 'gatsby-image'
import React from 'react'
import { Flex } from 'rebass'

import Layout from '../../components/Layout'
import { Container } from '../../components/styled'
import {
  StyledGridContainer,
  StyledBoxText,
  StyledTextUppercase,
} from './team.styled'

export const TeamTemplate = ({ teamImage, teamDescription, team }) => (
  <Layout>
    <Container>
      <Img fluid={teamImage.fluid} backgroundColor="#eeeeee" />
      <StyledBoxText
        fontSize={1}
        mt={3}
        mb={4}
        dangerouslySetInnerHTML={{
          __html: teamDescription.childMarkdownRemark.html,
        }}
      />
      <StyledGridContainer mb={3}>
        {team.map(member => (
          <Flex
            key={member.id}
            flexDirection="column"
            justifyContent="space-between"
          >
            <div>
              <Img fluid={member.image.fluid} backgroundColor="#eeeeee" />
              <StyledBoxText
                fontSize={1}
                my={3}
                dangerouslySetInnerHTML={{
                  __html: member.description.childMarkdownRemark.html,
                }}
              />
            </div>
            <div>
              <StyledTextUppercase>{member.name}</StyledTextUppercase>
              <StyledTextUppercase fontSize={1}>
                {member.position}
              </StyledTextUppercase>
            </div>
          </Flex>
        ))}
      </StyledGridContainer>
    </Container>
  </Layout>
)
